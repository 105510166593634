import { Injectable } from '@angular/core';
import { CoreFeedbackMessageService, Notification } from '@ev-portals/ev/frontend/util';

/**
 * Service for displaying feedback messages in the BaseLayoutComponent
 */
@Injectable({
  providedIn: 'root',
})
export class FeedbackMessageService extends CoreFeedbackMessageService {
  #impersonationNotificationId = 'impersonationNotification';
  #internalUserNotificationId = 'internalUserNotification';
  #errorMessageId = 'errorMessageId';

  removeImpersonationNotification(): void {
    this.removeNotificationById(this.#impersonationNotificationId);
  }

  public addInfoNotification(message: string): void {
    const notification: Notification = {
      id: Date.now().toString(),
      type: 'info',
      title: message,
      message: '',
      closable: true,
    };

    this.addNotification(notification);
  }

  addErrorNotification(message?: string): void {
    const notification: Notification = {
      id: this.#errorMessageId,
      type: 'error',
      title: this.DEFAULT_ERROR_MESSAGE,
      message: message ?? '',
      closable: true,
    };

    this.addNotification(notification);
  }

  addImpersonationNotification(): void {
    const notification: Notification = {
      id: this.#impersonationNotificationId,
      type: 'info',
      title: $localize`You are currently in Impersonation Mode.`,
      message: $localize`Functionality during impersonation might be limited. <a href="https://confluence.basf.net/display/MyPC/User+Impersonation" target="_blank">Click here to learn more.</a>`,
      closable: false,
    };

    this.addNotification(notification);
  }

  addInternalUserNotification(): void {
    const notification: Notification = {
      id: this.#internalUserNotificationId,
      type: 'info',
      title: $localize`You are currently logged in with an internal User.`,
      message: $localize`Functionality for internal users can be limited. <a href="https://confluence.basf.net/display/MyPC/SmartCard+Login" target="_blank">Click here to learn more.</a>`,
      closable: false,
    };

    this.addNotification(notification);
  }

  removeInternalUserNotification(): void {
    this.removeNotificationById(this.#internalUserNotificationId);
  }
}
