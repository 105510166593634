import { Injectable } from '@angular/core';
const WINDOW = window as any;

@Injectable({
  providedIn: 'root',
})
export class GlobalDataService {
  constructor() {
    // Initialize the globalData object on the window object
    WINDOW.globalData = {};
  }
  // Could be separated into a separate service
  patchGlobalData(partialData: Partial<GlobalData>): void {
    // Merge the new params into the globalData object
    WINDOW.globalData = { ...WINDOW.globalData, ...partialData };
  }

  clearGlobalData(): void {
    WINDOW.globalData = {};
  }
}

export interface GlobalData {
  userEmail: string;
  isInternalUser: boolean;
}
