import { Location } from '@angular/common';
import { inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  NavigationEnd,
  NavigationExtras,
  Router,
  UrlCreationOptions,
  UrlTree,
} from '@angular/router';
import { SimpleFilterIdEnum } from '@ev-portals/cp/frontend/shared/api-client';
import { BusinessSegment } from '@ev-portals/cp/frontend/shared/model';
import { DialogService } from '@ev-portals/ev/frontend/util';
import { BehaviorSubject } from 'rxjs';

import { AnalyticsService } from './analytics.service';
import { Environment, MenuName, Page, PageName } from './model';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  #location = inject(Location);
  #router = inject(Router);
  #analyticsService = inject(AnalyticsService);
  #dialogService = inject(DialogService);
  #environment = inject(Environment);
  activeLocale = inject(LOCALE_ID);

  #defaultActiveMenuName: MenuName = 'home';
  #_activeMenuName$ = new BehaviorSubject<MenuName | null>(this.#defaultActiveMenuName);
  activeMenuName$ = this.#_activeMenuName$.asObservable();
  activePageName$ = new BehaviorSubject<PageName | null>(null);
  // Track pages that should not be tracked automatically
  #manuallyTrackedPages: PageName[] = ['product-details', 'privacy-policy'];

  constructor() {
    this.#router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Note: We need this, as 'scrollPositionRestoration' doesn't work due to atom-shell
        this.scrollToTop();
        const activePage = this.#getPageByUrl(event.url);

        if (!activePage) {
          // Alert to support debugging, but only in non-prod environments
          if (this.#environment.environment !== 'prod') {
            this.#dialogService.alert(`[NavigationService] Page not found: ${event.url}`);
          }
          return;
        }

        this.activePageName$.next(activePage.name);

        this.#_activeMenuName$.next(activePage.menuName ?? this.#defaultActiveMenuName);

        // Track page view, if it's not manually tracked
        if (!this.#manuallyTrackedPages.includes(activePage.name)) {
          this.#analyticsService.viewPage(activePage.name, activePage.menuName);
        }
      }
    });
  }

  changeLocale(newLocale: string): void {
    window.location.replace(window.location.href.replace(this.activeLocale, newLocale));
  }

  removeQueryParam(urlString: string, queryParamKey: string): string {
    // Create a URL object
    const url = new URL(urlString, 'http://dummy.com'); // Adding dummy base for relative URLs

    // Remove the sso parameter
    url.searchParams.delete(queryParamKey);

    // Return the updated URL
    return url.pathname + url.search;
  }

  #getPageByUrl(url: string): Page | undefined {
    // We need to reverse the pages array, because the first match will be returned (we're filtering out the more detailed urls first)
    return [...pages].reverse().find(({ urlRegexp }) => urlRegexp.test(url));
  }

  storeOriginalDestination(destinationUrl = this.#router.url): void {
    this.setOriginalDestination(destinationUrl);
  }

  navigateToOriginalDestination(): Promise<boolean> {
    // First let's get the url that the user originally wanted to access
    const originalDestination = this.getOriginalDestination() ?? '';
    this.removeOriginalDestination();
    // We are good to go, let's navigate to that original url
    return this.#router.navigateByUrl(originalDestination);
  }

  // Navigate back if possible, if possible returns true otherwise false
  async navigateBack(): Promise<boolean> {
    const originalLocation = this.#location.path(true);
    this.#location.back();

    // Wait for the next execution cycle, so that the location is updated
    await new Promise(resolve => setTimeout(resolve, 0));

    return this.#location.path(true) !== originalLocation;
  }

  refreshPage(): void {
    window.location.reload();
  }

  navigateToRegister(originalUrl?: string): Promise<boolean> {
    return this.#router.navigate(['/account-register'], {
      queryParams: { redirectUrl: originalUrl },
    });
  }

  navigateToSelectLocation(): Promise<boolean> {
    return this.#router.navigate([this.getUrlByPageName('select-location')]);
  }

  navigateToRequestHistory(): Promise<boolean> {
    return this.#router.navigate(['/user/request-history']);
  }

  navigateToTermsAndConditions(): void {
    const url = this.#router.serializeUrl(
      this.#router.createUrlTree([`/info/terms-and-conditions`]),
    );

    window.open(url, '_blank');
  }

  navigateToPurchaseConfirmation(): Promise<boolean> {
    return this.#router.navigate([`/cart/confirmation`]);
  }

  navigateToRequests(navSettings: NavigationExtras = {}): Promise<boolean> {
    return this.#router.navigate([`/requests/send`], navSettings);
  }

  navigateToRequestSample(productIds?: string[]): Promise<boolean> {
    const queryParams = {
      productIds: productIds?.join(','),
      requestCategory: 'sample',
    };

    return this.navigateToRequests({ queryParams });
  }

  navigateToRequestPrice(productIds?: string[]): Promise<boolean> {
    const queryParams = {
      productIds: productIds?.join(','),
      requestCategory: 'commercial',
    };

    return this.navigateToRequests({ queryParams });
  }

  navigateToFirstTimeOrder(productIds?: string[]): Promise<boolean> {
    const queryParams = {
      productId: productIds?.join(','),
      requestCategory: 'commercial',
    };

    return this.navigateToRequests({ queryParams });
  }

  navigateToHome(): Promise<boolean> {
    return this.#router.navigate(['']);
  }

  navigateToForbiddenPage(): Promise<boolean> {
    return this.#router.navigate(['/error/403']);
  }

  navigateToNotFoundPage(): Promise<boolean> {
    return this.#router.navigate(['/error/404']);
  }

  navigateToNoRolePage(): Promise<boolean> {
    return this.#router.navigate(['/error/no-role-assigned']);
  }

  navigateToProducts(): Promise<boolean> {
    return this.#router.navigate(['/products']);
  }

  navigateToLogin(): Promise<boolean> {
    return this.#router.navigate(['/login']);
  }

  navigateToProductsWithBusinessSegment(businessSegment: BusinessSegment): Promise<boolean> {
    const queryParams = {
      localFilters: `{ "${SimpleFilterIdEnum.BusinessSegmentFilter}": "${businessSegment}" }`,
    };

    return this.#router.navigate(['/products'], { queryParams });
  }

  navigateToProductDetails(productId: string, slug?: string): Promise<boolean> {
    // use old url structure if slug is not available
    if (!slug) {
      return this.#router.navigate([`/products/${productId}`]);
    }

    return this.#router.navigate([`/products/${slug}/p/${productId}`]);
  }

  navigateToPurchase(productId: string): void {
    const worldAccountUrl = `https://worldaccount.basf.com/en_US/logged-in/product-catalog.html?&prd=${productId}`;
    window.open(worldAccountUrl, '_blank');
  }

  navigateToMyProfile(): Promise<boolean> {
    return this.#router.navigate(['/user/profile']);
  }

  navigateToFinancialDocs(): Promise<boolean> {
    return this.#router.navigate(['/user/financial-docs']);
  }

  navigateToOrderHistory(): Promise<boolean> {
    return this.#router.navigate(['/user/order-history']);
  }

  navigateToOrderDetails(orderId: string): Promise<boolean> {
    return this.#router.navigate([`/user/order-history/${orderId}`]);
  }

  navigateToDeliveryDocuments(): Promise<boolean> {
    return this.#router.navigate(['/user/delivery-documents']);
  }

  navigateToSavedCarts(): Promise<boolean> {
    return this.#router.navigate(['/user/saved-carts']);
  }

  navigateToSavedCartDetails(cartId: string): Promise<boolean> {
    return this.#router.navigate([`/user/saved-carts/${cartId}`]);
  }

  navigateToContact(): Promise<boolean> {
    return this.#router.navigate(['/info/contact']);
  }

  navigateToCart(): Promise<boolean> {
    return this.#router.navigate(['/cart']);
  }

  scrollToTop(): void {
    document.querySelector('#wrapper')?.scrollTo(0, 0);
  }

  getUrlByPageName(pageName: PageName): string {
    const page = pages.find(page => page.name === pageName);

    if (!page || !page.url) {
      throw new Error(`Page not found: ${pageName}`);
    }

    return page.url;
  }

  getUrlTreeByPageName(pageName: PageName, navigationExtras?: UrlCreationOptions): UrlTree {
    return this.#router.createUrlTree([this.getUrlByPageName(pageName)], navigationExtras);
  }

  getOriginalDestination(): string | null {
    return localStorage.getItem('originalDestination');
  }

  setOriginalDestination(destinationUrl: string): void {
    localStorage.setItem('originalDestination', destinationUrl);
  }

  removeOriginalDestination(): void {
    localStorage.removeItem('originalDestination');
  }
}

export const pages: Page[] = [
  // Regexp explanation: after the '/' query parameters are allowed, but not required
  { name: 'home', urlRegexp: /^\/(\?.*)?$/i, menuName: 'home' },
  { name: 'login', url: '/login', urlRegexp: /^\/login/, menuName: undefined },
  {
    name: 'error-403',
    url: '/error/403',
    urlRegexp: /^\/error\/403/,
    menuName: 'error',
  },
  {
    name: 'error-404',
    url: '/error/404',
    urlRegexp: /^\/error\/404/,
    menuName: 'error',
  },
  {
    name: 'error-no-role-assigned',
    url: '/error/no-role-assigned',
    urlRegexp: /^\/error\/no-role-assigned/,
    menuName: 'error',
  },
  { name: 'product-list', urlRegexp: /^\/products/, menuName: 'products' },
  {
    name: 'product-details',
    urlRegexp: /^\/products\/.*/, // matches all product details urls, als with slug and without slug
    menuName: 'products',
  },
  {
    name: 'events',
    urlRegexp: /^\/events/,
    menuName: 'events',
  },
  {
    name: 'event-details',
    urlRegexp: /^\/events\//,
    menuName: 'events',
  },
  {
    name: 'pcf',
    urlRegexp: /^\/products\/pcf/,
    menuName: 'products',
  },
  { name: 'requests', urlRegexp: /^\/requests\/send/, menuName: 'requests' },
  {
    name: 'privacy-policy',
    urlRegexp: /^\/info\/privacy-policy/,
    menuName: 'privacy-policy',
  },
  {
    name: 'terms-of-use',
    urlRegexp: /^\/info\/terms-of-use/,
    menuName: 'terms-of-use',
  },
  {
    name: 'terms-and-conditions',
    urlRegexp: /^\/info\/terms-and-conditions/,
    menuName: 'terms-and-conditions',
  },
  { name: 'contact', urlRegexp: /^\/info\/contact/, menuName: 'contact' },
  {
    name: 'financial-docs',
    urlRegexp: /^\/user\/financial-docs/,
    menuName: 'financial-docs',
  },
  {
    name: 'order-history',
    urlRegexp: /^\/user\/order-history/,
    menuName: 'order-history',
  },
  {
    name: 'order-history-details',
    urlRegexp: /^\/user\/order-history\//,
    menuName: 'order-history',
  },
  {
    name: 'delivery-documents',
    urlRegexp: /^\/user\/delivery-documents/,
    menuName: 'delivery-documents',
  },
  {
    name: 'saved-carts',
    urlRegexp: /^\/user\/saved-carts/,
    menuName: 'saved-carts',
  },
  {
    name: 'saved-cart-details',
    urlRegexp: /^\/user\/saved-carts\//,
    menuName: 'saved-carts',
  },
  {
    name: 'request-history',
    urlRegexp: /^\/user\/request-history/,
    menuName: 'request-history',
  },
  {
    name: 'request-history-details',
    urlRegexp: /^\/user\/request-history\//,
    menuName: 'request-history',
  },
  {
    name: 'user-profile',
    urlRegexp: /^\/user\/profile/,
    menuName: 'user-profile',
  },
  { name: 'cart', urlRegexp: /^\/cart/, menuName: 'cart' },
  {
    name: 'select-location',
    url: '/select-location',
    urlRegexp: /^\/select-location/,
    menuName: 'select-location',
  },
  {
    name: 'explore',
    urlRegexp: /^\/explore\/thickener-explorer/,
    menuName: 'explore',
  },
  {
    name: 'account-register',
    url: '/account-register',
    urlRegexp: /^\/account-register/,
    menuName: 'account-register',
  },
];
