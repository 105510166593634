import { inject, Injectable, LOCALE_ID } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class PrimeNgConfigService {
  // this are the names of the files in the primelocale package (don't change!!!!)
  private primeNGLocaleConfigNames: Record<Locales, string> = {
    'en-US': 'en-us',
    'en-GB': 'en-gb',
    'es-MX': 'es',
    'pt-BR': 'pt-br',
    'fr-CA': 'fr',
    'zh-CN': 'zh-CN',
    'zh-TW': 'zh-TW',
    'ko-KR': 'ko',
    'ja-JP': 'ja',
    'vi-VN': 'vi',
  };

  // PrimeNG locale configuration
  private locale = inject(LOCALE_ID);
  private primengConfig = inject(PrimeNGConfig);

  // Example method
  public initConfig() {
    const primeNgLocaleFileName = this.primeNGLocaleConfigNames[this.locale as Locales];

    import(`../../../../../../../node_modules/primelocale/${primeNgLocaleFileName}.json`)
      .then(module => {
        const localeData = module[primeNgLocaleFileName];
        this.primengConfig.setTranslation(localeData);
      })
      .catch(error => console.error(error));
  }
}

type Locales =
  | 'en-US'
  | 'en-GB'
  | 'es-MX'
  | 'pt-BR'
  | 'fr-CA'
  | 'zh-CN'
  | 'zh-TW'
  | 'ko-KR'
  | 'ja-JP'
  | 'vi-VN';
